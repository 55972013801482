<template>
  <div class="page">
    <component
        v-if="$layout.currentPage"
        :is="$layout.currentPage.pageComponentName"
        :record="$layout.currentPage">
    </component>
  </div>
</template>
<script>
import Poster from "@/components/organisms/poster";
import BoxMenu from "@/components/organisms/box-menu";
import IcoTxtTxt from "@/components/atoms/ico-txt-txt";
import IcoTxtTxtList from "@/components/molecules/infographies-list";
import Blocks from "@/Models/blocks/blocks";
import BlockTitre from "@/Models/blocks/texte/titre-block";
import BlockParagraph from "@/Models/blocks/texte/paragraph-block";
import ContainerGridSize from "@/components/atoms/container-grid-size";
export default {
  name: 'PageUid',
  components: {ContainerGridSize, BlockParagraph, BlockTitre, Blocks, IcoTxtTxtList, IcoTxtTxt, BoxMenu, Poster},
  mounted() {
    //console.log("page uid mounted");
  },
  computed:{
    /**
     *
     * @return {DbRecord|null}
     */
    record(){
      return this.$db.getByUid(this.$layout.currentUid);
    },
  },
  methods:{

  },
  watch:{
    record(){
      if(this.record){
        let me=this;
        console.log("new record displayed",this.record.uid);
        setTimeout(function(){
          me.record.html=document.getElementById("app").innerHTML;
          this.$db.store(me.record,function(data){
            console.log("setStoreRecord",data);
          });

        },100)

      }

    }
  }
}
</script>
