<template>
  <div class="d-flex titre-icon-bas"
       v-intersect="defaultIntersect"
       :class="{'justify-center':center}">
    <div class="mask up" :class="{invp:isIntersecting}">
      <div>
        <slot></slot>
      </div>
    </div>
    <div class="mask down icon" :class="{invp:isIntersecting}">
      <btn-arrow v-if="linkItem"
                 :link-item="linkItem"/>
      <v-icon v-else-if="arrow"
              class="icon-size">
        iffdec-bas
      </v-icon>

    </div>
  </div>
</template>

<script>
import InVpMixin from "@/components/atoms/InVpMixin";
import BtnArrow from "@/components/atoms/btn-arrow";

export default {
  name: "titre-icon-bas-wrapper",
  components: {BtnArrow},
  mixins:[InVpMixin],
  props:{
    linkItem:{
      type:Object
    },
    arrow:{
      type:Boolean,
      default:true
    },
    center:{
      type:Boolean,
      default:false
    }
  }

}
</script>

<style lang="less">
.titre-icon-bas{
  .h1,.h2,.h3,.h4{
    //line-height: normal;
  }
  .icon{
    margin-left: 3vw;
    align-self: center;
  }
}
</style>