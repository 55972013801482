<template>
<div class="block-titre">
  <div v-if="blc.isAnchor" :id="blc.uid"></div>
  <block-classic-container>
    <titre-icon-bas-wrapper
        :linkItem="linkItem"
        :arrow="blc.arrow"
        :center="blc.center"
    >
      <component
          :is="blc.seo"
          :class="`${blc.cssClass}`">
        {{ blc.text }}
      </component>
    </titre-icon-bas-wrapper>
  </block-classic-container>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import BlockClassicContainer from "@/Models/blocks/block-classic-container";
import TitreIconBasWrapper from "@/components/molecules/titre-icon-bas-wrapper";
import TitreLienMixin from "@/Models/blocks/texte/TitreLienMixin";

export default {
  name: "titre-block",
  components: {
    TitreIconBasWrapper,
    BlockClassicContainer
  },
  mixins:[BlockMixin,TitreLienMixin],
  computed:{
    /**
     *
     * @return {TitreBlock}
     */
    blc(){
      return this.block
    }
  }
}
</script>
