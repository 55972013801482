<template>
<div class="paragraph-block" :class="{'framed':blc.framed}">
  <div v-if="blc.isAnchor" :id="blc.uid"></div>
  <container-grid-size >
    <gradient-blue :class="{'big py-line-2':blc.framed}" :transparent="!blc.framed">
      <v-row>
        <v-col sm="10" offset-sm="1"
               class="has-href-underline"
        >
          <h2 v-if="blc.framed && blc.title" class="h3 mb-line-1">{{blc.title}}</h2>
          <v-row>
            <v-col cols="12" :md="blc.twoCols?'6':'12'">
              <div class="txt-paragraph" :class="blc.size" v-html="blc.text"/>
            </v-col>
            <v-col v-if="blc.twoCols" :md="blc.twoCols?'6':'12'">
              <div class="txt-paragraph pl-lg-5" :class="blc.size" v-html="blc.text2"/>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </gradient-blue>

  </container-grid-size>
</div>
</template>

<script>
import BlockMixin from "@/Models/blocks/BlockMixin";
import ContainerGridSize from "@/components/atoms/container-grid-size";
import GradientBlue from "@/components/atoms/gradient-blue";

export default {
  name: "paragraph-block",
  components: {GradientBlue, ContainerGridSize},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {ParagraphBlock}
     */
    blc(){
      return this.block;
    }
  }
}
</script>

<style lang="less">
.paragraph-block{
  .txt-paragraph.annotation{
    @media(min-width: @screen-md){
      width: 50%;
    }
    font-size: var(--fs-annotation) !important;
    *{
      font-size: var(--fs-annotation) !important;
    }
  }
  .bg-gradient-blue{
    color: #FFF;
  }

  ul{
    list-style:none;
    text-indent: 0;
    margin-left: 0;
    padding: 0;
    li{
      &:before{
        display: inline-block;
        margin-right: 10px;
        content: "• ";
        float: left;
      }
    }
  }


}
</style>